import React, { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import { exportDefaultSpecifier } from '@babel/types';

export default function Action() {

    const [ThumbUp, setThumbUp] = useState(0)
    const [ThumbDown, setThumbDown] = useState(0)
    const [Love, setLove] = useState(0)
    const [Likes, setLikes] = useState(0)
    const [Dislikes, setDislikes] = useState(0)

    function HandleClicked(value, setValue) {
        if (value == 1) {
            setValue(0)
        } else {
            setValue(1)
            if (ThumbUp == 1 || Love == 1) {

            } else if(Dislikes == 0){
                const summation = Likes + 1;
                setLikes(summation)
            } else {
                const result = Dislikes - 1;
                const summation = Likes + 1;
                setDislikes(result)
                setLikes(summation)

            }

        }
        setThumbDown(0)
    }

    function HandleClicked2(value, setValue) {
        if (value == 1) {
            setValue(0)
        } else {
            setValue(1)
            if (Likes == 0) {
                const result = Dislikes + 1;
                setDislikes(result)
            } else {
                const result = Dislikes + 1;
                const summation = Likes - 1;
                setDislikes(result)
                setLikes(summation)
            }
        }
        setLove(0)
        setThumbUp(0)

    }

    return (
        <div class="intro-y col-span-12 md:col-span-6 xl:col-span-4 box">
            <div class="flex items-center border-b border-gray-200 dark:border-dark-5 px-5 py-4">
                <div class="w-10 h-10 flex-none image-fit">
                    <img alt="Rubick Tailwind HTML Admin Template" class="rounded-full" src="dist/images/profile-5.jpg" />
                </div>
                <div class="ml-3 mr-auto">
                    <a href="" class="font-medium">Will Smith</a>
                    <div class="flex text-gray-600 truncate text-xs mt-0.5"> <a class="text-theme-1 dark:text-theme-10 inline-block truncate" href="">Smartphone &amp; Tablet</a> <span class="mx-1">•</span> 10 seconds ago </div>
                </div>
                <div class="dropdown ml-3">
                    {/* <a href="javascript:;" class="dropdown-toggle w-5 h-5 text-gray-600 dark:text-gray-300" aria-expanded="false"> <FeatherIcon icon="more-vertical" class="w-4 h-4" /> </a> */}
                    <div class="dropdown-menu w-40">
                        {/* <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                        <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <FeatherIcon icon="edit-2" class="w-4 h-4 mr-2"/> Edit Post </a>
                                        <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <FeatherIcon icon="trash" class="w-4 h-4 mr-2"/> Delete Post </a>
                                    </div> */}
                    </div>
                </div>
            </div>
            <div class="p-5">
                <div class="h-40 2xl:h-56 image-fit">
                    <img alt="Rubick Tailwind HTML Admin Template" class="rounded-md" src="dist/images/preview-14.jpg" />
                </div>
                <a href="" class="block font-medium text-base mt-5">Popularised in the 1960s with the release of Letraset</a>
                <div class="text-gray-700 dark:text-gray-600 mt-2">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 20</div>
            </div>
            <div class="flex items-center px-5 py-3 border-t border-gray-200 dark:border-dark-5">
                <div onClick={() => HandleClicked(ThumbUp, setThumbUp)} class={ThumbUp == 1 ? "intro-x w-8 h-8 flex items-center justify-center rounded-full bg-theme-1 text-white dark:border-dark-5 dark:bg-dark-5 dark:text-gray-300 text-gray-600 mr-2 tooltip" : "intro-x w-8 h-8 flex items-center justify-center rounded-full border border-gray-400 dark:border-dark-5 dark:bg-dark-5 dark:text-gray-300 text-gray-600 mr-2 tooltip"} title="Like"> <FeatherIcon icon="thumbs-up" style={{ height: 15 }} class="w-3 h-3" /> </div>
                <div onClick={() => HandleClicked(Love, setLove)} class={Love == 1 ? "intro-x w-8 h-8 flex items-center justify-center rounded-full  bg-theme-1 text-white dark:text-gray-300  ml-auto tooltip" : "intro-x w-8 h-8 flex items-center justify-center rounded-full border border-gray-400 dark:text-gray-300 text-theme-10 ml-auto tooltip"} title="Love"> <FeatherIcon icon="heart" style={{ height: 15, }} class="w-3 h-3" /> </div>
                <div onClick={() => HandleClicked2(ThumbDown, setThumbDown)} class={ThumbDown == 1 ? "intro-x w-8 h-8 flex items-center justify-center rounded-full bg-theme-1 text-white ml-2 tooltip" : "intro-x w-8 h-8 flex items-center justify-center rounded-full border border-gray-400 ml-2 tooltip"} title="Dislikes"> <FeatherIcon icon="thumbs-down" style={{ height: 15 }} class="w-3 h-3" /> </div>
            </div>
            <div class="px-5 pt-3 pb-5 border-t border-gray-200 dark:border-dark-5">
                <div class="w-full flex text-gray-600 text-xs sm:text-sm">
                    <div class="mr-2"> Dislikes: <span class="font-medium">{Dislikes}</span> </div>
                    <div class="ml-auto"> Likes: <span class="font-medium">{Likes}</span> </div>
                </div>
            </div>
        </div>

    )
}