import React, { useState, useEffect } from 'react';
import Header from './Header';
import FeatherIcon from 'feather-icons-react';
import LineChart from './LineChart'
import Header2 from './Header2';
import 'react-calendar/dist/Calendar.css'
import DashboardHeader from './DashboardHeader';
import Cookies from 'js-cookie'
import axios from 'axios';

export default function Dashboard() {

    const [ThisMonth, setThisMonth] = useState();
    const [LastMonth, setLastMonth] = useState();
    const [Value, setValue] = useState(new Date());
    const [activity, setactivity] = useState(0)
    const [members, setmembers] = useState(0)
    const [task, settask] = useState(0)
    const [taskDone, setTaskDone] = useState(0)
    const [specialOffer, setspecialOffer] = useState(0)
    const storeddata = Cookies.get("user")
    console.log(storeddata)

    
    axios({
        method: 'get',
        url: "https://pure-crag-36612.herokuapp.com/getpartners",
        header: {
          "accept": "application/json",
          "Content-Type": "application/json"
        }
      }).then((d)=>{
        settask(d.data.length)
      })
      axios({
        method: 'get',
        url: "https://pure-crag-36612.herokuapp.com/getusers",
        header: {
          "accept": "application/json",
          "Content-Type": "application/json"
        }
      }).then((d)=>{
        setspecialOffer(d.data.length)
      })
   
    
    return (
        <body class="main">
            <Header2 />
            <div class="flex">
                <Header />
                <div class="content">
                    <DashboardHeader title="Dashboard" />
                    <div class="grid grid-cols-12 gap-6">
                        <div class="col-span-12 2xl:col-span-9">
                            <div class="grid grid-cols-12 gap-6">
                                <div class="col-span-12 mt-8">
                                    <div class="intro-y flex items-center h-10">
                                        <h2 class="text-lg font-medium truncate mr-5">
                                            General Report
                                    </h2>
                                        <a href="" class="ml-auto flex items-center text-theme-1 dark:text-theme-10"> <FeatherIcon icon="refresh-ccw" class="w-4 h-4 mr-3" /> Reload Data </a>
                                    </div>
                                    <div class="grid grid-cols-12 gap-6 mt-5">
                                        <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                                            <div class="report-box zoom-in">
                                                <div class="box p-5">
                                                    <div class="flex">
                                                        <FeatherIcon icon="activity" class="report-box__icon text-theme-10" />
                                                        <div class="ml-auto">
                                                        </div>
                                                    </div>
                                                    <div class="text-3xl font-medium leading-8 mt-6">{activity}</div>
                                                    <div class="text-base text-gray-600 mt-1">This month active users</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                                            <div class="report-box zoom-in">
                                                <div class="box p-5">
                                                    <div class="flex">
                                                        <FeatherIcon icon="briefcase" class="report-box__icon text-theme-11" />
                                                    </div>
                                                    <div class="text-3xl font-medium leading-8 mt-6">{task}</div>
                                                    <div class="text-base text-gray-600 mt-1">Numbers of potential partners </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                                            <div class="report-box zoom-in">
                                                <div class="box p-5">
                                                    <div class="flex">
                                                        <FeatherIcon icon="check-circle" class="report-box__icon text-theme-12" />
                                                        <div class="ml-auto">
                                                        </div>
                                                    </div>
                                                    <div class="text-3xl font-medium leading-8 mt-6">{taskDone}</div>
                                                    <div class="text-base text-gray-600 mt-1">Total numbers of users visited</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                                            <div class="report-box zoom-in">
                                                <div class="box p-5">
                                                    <div class="flex">
                                                        <FeatherIcon icon="user" class="report-box__icon text-theme-9" />
                                                        <div class="ml-auto">
                                                        </div>
                                                    </div>
                                                    <div class="text-3xl font-medium leading-8 mt-6">{specialOffer}</div>
                                                    <div class="text-base text-gray-600 mt-1">Numbers of special offer</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-span-12 lg:col-span-6 mt-8">
                                    <div class="intro-y block sm:flex items-center h-10">
                                        <h2 class="text-lg font-medium truncate mr-5">
                                            User par month Report
                                    </h2>
                                    </div>
                                    <div class="intro-y box p-5 mt-12 sm:mt-5">
                                        <div class="flex flex-col xl:flex-row xl:items-center">
                                            <div class="flex">
                                                <div>
                                                    <div class="text-theme-19 dark:text-gray-300 text-lg xl:text-xl font-medium" style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>N{ThisMonth}</div>
                                                    <div class="mt-0.5 text-gray-600 dark:text-gray-600">This Month</div>
                                                </div>
                                                <div class="w-px h-12 border border-r border-dashed border-gray-300 dark:border-dark-5 mx-4 xl:mx-5"></div>
                                                <div>
                                                    <div class="text-gray-600 dark:text-gray-600 text-lg xl:text-xl font-medium" style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>N{LastMonth}</div>
                                                    <div class="mt-0.5 text-gray-600 dark:text-gray-600">Last Month</div>
                                                </div>
                                            </div>
                                            <div class="dropdown xl:ml-auto mt-5 xl:mt-0">
                                                <button class="dropdown-toggle btn btn-outline-secondary font-normal" aria-expanded="false"> Filter by year <FeatherIcon icon="chevron-down" class="w-4 h-4 ml-2" /> </button>
                                                <div class="dropdown-menu w-40">
                                                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2 overflow-y-auto h-32"> <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">2022</a> </div>
                                                </div>
                                            </div>
                                        </div>
                                        <LineChart />

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    )
}