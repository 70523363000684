import React from 'react'
import FeatherIcon from 'feather-icons-react';
import {useLocation, Link} from 'react-router-dom'

export default function NavigationBar({title, icon,path}){
    return(
        <li>
        <Link to={path} class={useLocation().pathname=== path?"menu menu--active":"menu"}>
            <div class="menu__icon"> <FeatherIcon icon={icon} /> </div>
            <div class="menu__title"> {title}  </div>
        </Link>
    </li>
    )
}