import React from 'react';
import axios from 'axios'

 function GetData(url){
console.log(url)
    axios({
        method: 'get',
        url: url,
        header: {
          "accept": "application/json",
          "Content-Type": "application/json"
        }
      }).then((res) => {
        
        console.log(res)
      }).catch(err => {
     console.log(err)  
    })
}

export {GetData}