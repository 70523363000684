import React from 'react';
import axios from 'axios'
import Cookies from 'js-cookie'

function Password(url, data , navigation, alert){
    axios({
        method: 'post',
        url: url,
        header: {
          "accept": "application/json",
          "Content-Type": "application/json"
        },
        data:data
      }).then((res) => {
       if(res.status == 200){
       alert.show("Email has been sent")
      }
      }).catch(err => {
          console.log(err)
        alert.show("Something went wrong") 
    })

}

export { Password}