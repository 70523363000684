import React from 'react'
import { useNavigate, Outlet } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import env from "react-dotenv";
import { PostData } from '../SendData/Postdata'
import {useAlert} from 'react-alert';

export default function () {

    const navigation = useNavigate();
    const alert = useAlert()

    function handleSubmit(a, b) {
     
        PostData(env.DB_SIGNUP, a, navigation, alert)
    }

    
    return (
        <div class="login">
            <div class="container sm:px-10">
                <div class="block xl:grid grid-cols-2 gap-4">
                    <div class="hidden xl:flex flex-col min-h-screen">
                        <a href="" class="-intro-x flex items-center pt-5">
                            <img alt="Rubick Tailwind HTML Admin Template" class="w-6" src="favicon.ico" style={{ height: 50, width: 50 }} />
                            <span class="text-white text-lg ml-3"> Cycle<span class="font-medium">breeze</span> </span>
                        </a>
                        <div class="my-auto">
                            <img alt="Rubick Tailwind HTML Admin Template" class="-intro-x w-1/2 -mt-16" src="dist/images/illustration.svg" />
                            <div class="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                                A few more clicks to
                            <br />
                                sign up to your account.
                        </div>
                            <div class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500">Manage your Cyclebreeze Community accounts.</div>
                        </div>
                    </div>
                    <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
                        <div class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
                            <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
                                Sign Up
                        </h2>
                            <Formik
                                initialValues={{ email: "", password: "", first: "", last: "", role: "", confirm: "", checkbox: "" }}
                                validationSchema={Yup.object({
                                    first: Yup.string().required("Required"),
                                    first: Yup.string().required("Required"),
                                    last: Yup.string().required("Required"),
                                    role: Yup.string().required("Required"),
                                    userName: Yup.string().required("Required"),
                                    password: Yup.string().required("Required"),
                                    confirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required("Required"),
                                    email: Yup.string().email('invalid email address').required('Required'),
                                    checkbox: Yup.string().required("Required")

                                })}

                                onSubmit={(values, { setSubmitting }) => {
                                    handleSubmit(values, setSubmitting)
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                }) => (
                                        <form onSubmit={handleSubmit}>

                                            <div class="intro-x mt-2 text-gray-500 dark:text-gray-500 xl:hidden text-center">A few more clicks to sign in to your account. Manage your Cyclebreeze Community accounts</div>
                                            <div class="intro-x mt-8">
                                                <Field class="intro-x login__input form-control py-3 px-4 border-gray-300 block" type="text" name="userName" placeholder="User Name" />
                                                <div style={{ color: "red" }}>
                                                    <ErrorMessage name="userName" />
                                                </div>
                                                <Field class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4" type="first" name="first" placeholder="First Name" />
                                                <div style={{ color: "red" }}>
                                                    <ErrorMessage name="first" />
                                                </div>
                                                <Field class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4" type="last" name="last" placeholder="Last Name" />
                                                <div style={{ color: "red" }}>
                                                    <ErrorMessage name="last" />
                                                </div>
                                                <Field class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4" type="role" name="role" placeholder="Role" />
                                                <div style={{ color: "red" }}>
                                                    <ErrorMessage name="role" />
                                                </div>
                                                <Field class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4" type="email" name="email" placeholder="Email" />
                                                <div style={{ color: "red" }}>
                                                    <ErrorMessage name="email" />
                                                </div>
                                                <Field class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4" type="password" name="password" placeholder="Password" />
                                                <div style={{ color: "red" }}>
                                                    <ErrorMessage name="password" />
                                                </div>
                                                <Field type="text" class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4" type="password" name="confirm" placeholder="Password Confirmation" />
                                                <div style={{ color: "red" }}>
                                                    <ErrorMessage name="confirm" />
                                                </div>
                                            </div>
                                            <div class="intro-x flex items-center text-gray-700 dark:text-gray-600 mt-4 text-xs sm:text-sm">
                                                <Field id="remember-me" type="checkbox" name="checkbox" class="form-check-input border mr-2" />

                                                <label class="cursor-pointer select-none" for="remember-me">I agree to the Cyclebreeze</label>
                                                <a class="text-theme-1 dark:text-theme-10 ml-1" href="">Privacy Policy</a>.
                                            </div>
                                            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                                                <button class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top" type="submit">Register</button>
                                                <button class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top" onClick={() => navigation('/Login')}>Sign in</button>
                                            </div>
                                        </form>
                                    )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}