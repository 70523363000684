import React from 'react';
import Header from '../Dashboard/Header';
import Header2 from '../Dashboard/Header2';
import DashboardHeader from '../Dashboard/DashboardHeader';

export default function Progression(){
    return(
        <body class="main">
        <Header2/>
        <div class="flex">
            <Header/>
            <div class="content">
                <DashboardHeader/>
                <div class="intro-y flex items-center mt-8">
                    <h2 class="text-lg font-medium mr-auto">
                        Cyclebreeze Community Membership Growth
                    </h2>
                </div>
                <div class="grid grid-cols-12 gap-6 mt-5">
                    <div class="intro-y col-span-12 lg:col-span-6">
                        <div class="intro-y box">
                            <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                                <h2 class="font-medium text-base mr-auto">
                                    Progressbar Height
                                </h2>
                                   </div>
                            <div id="progressbar-height" class="p-5">
                                <div class="preview">
                                <ol>
                    <li>
                        These are the requirement need to acquire this stage 
                    </li>

                    <li>
                        hello
                    </li>
                    <li>
                        hello
                    </li>
                    <li>
                        hello
                    </li>
                    <li>
                        hello
                    </li>
                    <li>
                        hello
                    </li>
                </ol>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </body>   
    )
}