import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie'
export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    auth: Cookies.get("auth"),
  },
  reducers: {
    auth: (state, action) => {
      state.auth = action.payload;
    },
    unauth: (state) => {
      state.auth = false;
    },
  },
});
export const { auth, unauth } = authSlice.actions;
export const selectauth = (state) => state.auth.auth;
export default authSlice.reducer;