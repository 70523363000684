import React from 'react';
import FeatherIcon from 'feather-icons-react';
import {useLocation, Link} from 'react-router-dom'

export default function SideBar({ icon, title, link }) {
    
    return (
        <li>
            <Link to={link} class={useLocation().pathname === link ? "side-menu side-menu--active" :"side-menu" }>
                <div class="side-menu__icon"> <FeatherIcon icon={icon} /> </div>
                <div class="side-menu__title">
                    {title}
                </div>
            </Link>
        </li>
    )
}