import React from 'react';
import Header from '../Dashboard/Header';
import Header2 from '../Dashboard/Header2';
import DashboardHeader from '../Dashboard/DashboardHeader';

export default function Gift() {
    return (
        <body class="main">
            <Header2 />
            <div class="flex">
                <Header />
                <div class="content">
                    <DashboardHeader title="Gift" />
                    <h2 class="intro-y text-lg font-medium mt-10">
                        Users who have recieved the special offer
                </h2>
                    <div class="grid grid-cols-12 gap-6 mt-5">
                        {/* <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
                            <button class="btn btn-primary shadow-md mr-2">Add New Product</button>
                            <div class="dropdown">
                                <button class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300" aria-expanded="false">
                                    <span class="w-5 h-5 flex items-center justify-center"> <i class="w-4 h-4" data-feather="plus"></i> </span>
                                </button>
                                <div class="dropdown-menu w-40">
                                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                        <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="printer" class="w-4 h-4 mr-2"></i> Print </a>
                                        <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="file-text" class="w-4 h-4 mr-2"></i> Export to Excel </a>
                                        <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="file-text" class="w-4 h-4 mr-2"></i> Export to PDF </a>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden md:block mx-auto text-gray-600">Showing 1 to 10 of 150 entries</div>
                            <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                                <div class="w-56 relative text-gray-700 dark:text-gray-300">
                                    <input type="text" class="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Search..." />
                                    <i class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" data-feather="search"></i>
                                </div>
                            </div>
                        </div> */}
                        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
                            <table class="table table-report -mt-2">
                                <thead>
                                    <tr>
                                        <th class="whitespace-nowrap">IMAGE</th>
                                        <th class="whitespace-nowrap">NAME</th>
                                        <th class="text-center whitespace-nowrap">Rate</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="intro-x">
                                        <td class="w-40">
                                            <div class="flex">
                                                <div class="w-10 h-10 image-fit zoom-in">
                                                    <img alt="Rubick Tailwind HTML Admin Template" class="tooltip rounded-full" src="dist/images/preview-3.jpg" title="Uploaded at 10 May 2021" />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <a href="" class="font-medium whitespace-nowrap">Sony Master Series A9G</a>
                                            <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">Electronic</div>
                                        </td>
                                        <td class="text-center">recieved</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0">
                                    <div class="p-5 text-center">
                                        <i data-feather="x-circle" class="w-16 h-16 text-theme-6 mx-auto mt-3"></i>
                                        <div class="text-3xl mt-5">Are you sure?</div>
                                        <div class="text-gray-600 mt-2">
                                            Do you really want to delete these records?
                                        <br />
                                            This process cannot be undone.
                                    </div>
                                    </div>
                                    <div class="px-5 pb-8 text-center">
                                        <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">Cancel</button>
                                        <button type="button" class="btn btn-danger w-24">Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    )
}