import React,{useState} from 'react'
import FeatherIcon from 'feather-icons-react';
import NavigationBar from './NavigationBar'
export default function SiderBar() {

    const [toggle, setToggle] = useState(true);

    function Touch(){
        if(toggle){
            setToggle(false)
        } else{
            setToggle(true)
        }
    }

    return (
        <div class="mobile-menu md:hidden">
            <div class="mobile-menu-bar">
                <div class="flex mr-auto">
                    <img alt="Rubick Tailwind HTML Admin Template" class="w-6" src="favicon.ico" style={{ width: 45, height: 45 }} />
                </div>
                <div onClick={()=>Touch()}> <FeatherIcon icon="bar-chart-2" class="w-8 h-8 text-white transform -rotate-90" /> </div>
            </div>
            {

                toggle &&
                <ul class="border-t border-theme-29 py-5">
                    <NavigationBar title="Dashboard" icon="home" path="/"/>
                <NavigationBar title="Potential Partners" icon="briefcase" path="/partner"/>
                <NavigationBar title="New special offer" icon="user" path="/task"/>
                <NavigationBar title="Successful Special offer" icon="gift" path="/reward"/>
                <NavigationBar title="Partners" icon="airplay" path="/RealPartner"/>
                   
                                  </ul>}
        </div>
    )
}