import React from 'react'

export default function About({name}) {
    return (
        <div class="relative flex items-center mt-5">
        <div class="w-12 h-12 flex-none image-fit">
            <img alt="Rubick Tailwind HTML Admin Template" class="rounded-full" src="favicon.ico" />
        </div>
        <div class="ml-4 mr-auto">
            <a href="" class="font-medium">{name}</a>
        </div>
        </div>
    )
}