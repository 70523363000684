import React from 'react';
import AccountSetting from "./AccountSettings";
import Cookies from 'js-cookie';

export default function SettingSideBar(){



    return(
        <div class="col-span-12 lg:col-span-4 2xl:col-span-3 flex lg:block flex-col-reverse">
        <div class="intro-y box mt-5">
            <div class="relative flex items-center p-5">
                <div class="w-12 h-12 image-fit">
                    <img alt="Rubick Tailwind HTML Admin Template" class="rounded-full" src="dist/images/profile-2.jpg" />
                </div>
                <div class="ml-4 mr-auto">
                    <div class="font-medium text-base" style={{textTransform:"capitalize"}}>{Cookies.get("last")} {Cookies.get("first")}</div>
                    <div class="text-gray-600">{Cookies.get("role")}</div>
                </div>
                <div class="dropdown">
                    <a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <i data-feather="more-horizontal" class="w-5 h-5 text-gray-600 dark:text-gray-300"></i> </a>
                    <div class="dropdown-menu w-56">
                        <div class="dropdown-menu__content box dark:bg-dark-1">
                            <div class="p-4 border-b border-gray-200 dark:border-dark-5 font-medium">Export Options</div>
                            <div class="p-2">
                                <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="activity" class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"></i> English </a>
                                <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                                    <i data-feather="box" class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"></i> Indonesia
                            <div class="text-xs text-white px-1 rounded-full bg-theme-6 ml-auto">10</div>
                                </a>
                                <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="layout" class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"></i> English </a>
                                <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="sidebar" class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"></i> Indonesia </a>
                            </div>
                            <div class="px-3 py-3 border-t border-gray-200 dark:border-dark-5 font-medium flex">
                                <button type="button" class="btn btn-primary py-1 px-2">Settings</button>
                                <button type="button" class="btn btn-secondary py-1 px-2 ml-auto">View Profile</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-5 border-t border-gray-200 dark:border-dark-5">
                <AccountSetting icon="activity" title="Personal Information" path="/settings" />
            </div>
        </div>
    </div>   
    )
}