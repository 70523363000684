import {Outlet} from 'react-router-dom'
import Login from '../Authentication/Login';
import Cookies from 'js-cookie'
import { auth } from '../features/authSlice';
import { useSelector } from 'react-redux';

    const ProtectedRoutes = (isAuth) =>{
        const isauth = useSelector(auth)
        
        return isauth.payload.auth.auth? <Outlet/> :<Login />;
    };
    export default ProtectedRoutes;