import React from 'react';
import FeatherIcon from 'feather-icons-react';
import SideBar from './SideBar'

export default function Header() {
    return (
        <nav class="side-nav"> 
            <a href="" class="-intro-x flex items-center pt-5">
                <img alt="Rubick Tailwind HTML Admin Template" class="w-6" src="favicon.ico" style={{ height: 50, width: 50 }} />
                <span class="text-white text-lg ml-3"> VIP<span class="font-medium"> FLEETS</span> </span>
            </a>
            <div class="side-nav__devider my-6"></div>
            <ul>
                <SideBar title="Dashboard" icon="home" link="/"/>
                <SideBar title="Potential Partners" icon="briefcase" link="/partner"/>
                <SideBar title="New special offer" icon="user" link="/task"/>
                <SideBar title="Successful Special offer" icon="gift" link="/reward"/>
                <SideBar title="Partners" icon="airplay" link="/RealPartner"/>
                  
            </ul>
        </nav>
    )
}