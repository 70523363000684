import React from 'react';
import axios from 'axios'

 function PostData(url, data, navigation, alert){
    axios({
        method: 'post',
        url: url,
        header: {
          "accept": "application/json",
          "Content-Type": "application/json"
        },
        data: { 
            "firstName":data.first,
             "lastName":data.last,
             "password":data.password,
             "userName":data.userName,
             "role":data.role,
             "email":data.email
        }
      }).then((res) => {     
          alert.show(res.data.message)
          navigation("/signup/verification")        
      }).catch(err => {
        console.log(err)
         alert.show("Complete your details")
    })

}

export {PostData}