import React from 'react'
import Lottie from 'react-lottie-player'
import emailverification from '../Asset/emailverification.json';
import emailsvg from '../Asset/emailsvg.jpg'

export default function VerificationPage() {
    return (
        <body class="login">
            <div class="container sm:px-10">
                <div class="block xl:grid grid-cols-2 gap-4">
                    <div class="hidden xl:flex flex-col min-h-screen">
                        <a href="" class="-intro-x flex items-center pt-5">
                            <img alt="Rubick Tailwind HTML Admin Template" class="w-6" src="favicon.ico" style={{ height: 50, width: 50 }} />
                            <span class="text-white text-lg ml-3"> Cycle<span class="font-medium">breeze</span> </span>
                        </a>
                        <div class="my-auto">
                            <img alt="Rubick Tailwind HTML Admin Template" class="-intro-x w-1/2 -mt-16" src={emailsvg} />
                            <div class="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                                A few more clicks to
                        <br />
                                Verify your Email.
                    </div>
                        </div>
                    </div>
                    <form style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
                            <div class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
                                <center  >  <Lottie
                                    loop
                                    animationData={emailverification}
                                    play
                                    style={{ width: "90%" }}
                                />
                                </center>
                                <div class="intro-x mt-2 text-gray-500 dark:text-gray-500 xl:hidden text-center">A few more clicks to verify your Email.</div>
                                <div class="intro-x flex items-center text-gray-700 dark:text-gray-600 mt-4 text-xs sm:text-sm">
                                    <label class="cursor-pointer select-none" for="remember-me">A mail has been sent to you, try check your spam if it is not found in the indox</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </body>
    )
}