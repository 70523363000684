import React from "react"
import Header from '../Dashboard/Header'
import Header2 from '../Dashboard/Header2'
import DashboardHeader from "../Dashboard/DashboardHeader";
import SettingSideBar from "./SettingSiderbar";
import Cookies from 'js-cookie';
export default function Account() {
    return (
        <body class="main">
            <Header2 />
            <div class="flex">
                <Header />
                <div class="content">
                    <DashboardHeader />
                    <div class="intro-y flex items-center mt-8">
                        <h2 class="text-lg font-medium mr-auto">
                            Update Profile
                </h2>
                    </div>
                    <div class="grid grid-cols-12 gap-6">
                        <SettingSideBar />
                        <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
                            <div class="intro-y box lg:mt-5">
                                <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                                    <h2 class="font-medium text-base mr-auto">
                                        Display Information
                            </h2>
                                </div>
                                <div class="p-5">
                                    <div class="flex flex-col-reverse xl:flex-row flex-col">
                                        <div class="flex-1 mt-6 xl:mt-0">
                                            <div class="grid grid-cols-12 gap-x-5">
                                                <div class="col-span-12 2xl:col-span-6">
                                                    <div>
                                                        <label for="update-profile-form-1" class="form-label">Display Name</label>
                                                        <input id="update-profile-form-1" type="text" class="form-control" placeholder="Input text" value={Cookies.get("last") + "  " + Cookies.get("first")} disabled />
                                                    </div>
                                                    <div>
                                                        <label for="update-profile-form-1" class="form-label">Role</label>
                                                        <input id="update-profile-form-1" type="text" class="form-control" placeholder="Input text" value={Cookies.get("role")} disabled />
                                                    </div>

                                                    <div class="mt-3">
                                                        <label for="update-profile-form-2" class="form-label">Skills</label>
                                                        <select id="update-profile-form-2" data-search="true" class="tom-select w-full">
                                                            <option value="1">Admiralty</option>
                                                            <option value="2">Aljunied</option>
                                                            <option value="3">Ang Mo Kio</option>
                                                            <option value="4">Bartley</option>
                                                            <option value="5">Beauty World</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-span-12 2xl:col-span-6">
                                                    <div class="mt-3 2xl:mt-0">
                                                        <label for="update-profile-form-3" class="form-label">Location</label>
                                                        <input id="update-profile-form-4" type="text" class="form-control" placeholder="city, state,country" />
                                                    </div>
                                                    <div class="mt-3">
                                                        <label for="update-profile-form-4" class="form-label">Phone Number</label>
                                                        <input id="update-profile-form-4" type="text" class="form-control" placeholder="...12345" />
                                                    </div>
                                                </div>
                                                <div class="col-span-12">
                                                    <div class="mt-3">
                                                        <label for="update-profile-form-5" class="form-label">About you</label>
                                                        <textarea id="update-profile-form-5" class="form-control" placeholder="young and passionate about tech"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" class="btn btn-primary w-20 mt-3">Save</button>
                                        </div>
                                        <div class="w-52 mx-auto xl:mr-0 xl:ml-6">
                                            <div class="border-2 border-dashed shadow-sm border-gray-200 dark:border-dark-5 rounded-md p-5">
                                                <div class="h-40 relative image-fit cursor-pointer zoom-in mx-auto">
                                                    <img class="rounded-md" alt="Rubick Tailwind HTML Admin Template" src="dist/images/profile-2.jpg" />
                                                    <div title="Remove this profile photo?" class="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 top-0 -mr-2 -mt-2"> <i data-feather="x" class="w-4 h-4"></i> </div>
                                                </div>
                                                <div class="mx-auto cursor-pointer relative mt-5">
                                                    <button type="button" class="btn btn-primary w-full">Change Photo</button>
                                                    <input type="file" class="w-full h-full top-0 left-0 absolute opacity-0" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>)
}