import React, { useEffect, useState } from 'react';
import Login from './Authentication/Login';
import Forgotten from './Authentication/Forgotten';
import SignUp from './Authentication/Signup';
import Dashboard from './Dashboard/Dashboard';
import Error from './Error/Error';
import { Routes, Route } from "react-router-dom";
import Profile from './profile/Profile';
import VerificationPage from './VerificationPage/VerificationPage';
import Calendar from './Calender/Calendar'
import Activity from './Activity/Activity';
import Partner from './Partner/Activity';
import RealPartner from './RealPartner/Activity';
import Task from './Task/Task';
import ViewTask from './Task/ViewTask';
import Reward from './Gift/Reward';
import Progression from './Progress/Progression'
import ProtectedRoutes from './ProtectedRoute/ProtectedRoute';
import Cookies from 'js-cookie'
import Reset_password from './reset_password/Reset_password'
import Subscribtion from './Price/Subscribtion';
import Account from './Account/Account';
import ResendEmail from './Authentication/ResendEmail';
import ConfirmEmail from './ConfirmEmail/ConfirmEmail';

function App() {
  return (
    <div>
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route path="*" element={<Error />} />
          <Route exact path="/task/view" element={<ViewTask />} />
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/activity" element={<Activity />} />
          <Route exact path="/partner" element={<Partner />} />
          <Route exact path="/calendar" element={<Calendar />} />
          <Route exact path="/task" element={<Task />} />
          <Route exact path="/resendEmailToken" element={<ResendEmail />} />
          <Route exact path="/Reward" element={<Reward />} />
          <Route exact path="/Progress" element={<Progression />} />
          <Route exact path="/settings/change_password" element={<Reset_password />} />
          <Route exact path="/subscribtion" element={<Subscribtion />} />
          <Route exact path="/settings" element={<Account />} />
          <Route exact path="/RealPartner" element={<RealPartner />} />
          <Route exact path="/confirm-email/:id" element={<ConfirmEmail />} />
        </Route>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgotten" element={<Forgotten />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/signup/verification" element={<VerificationPage />} />
        {!Cookies.get("auth") && <Route path="*" element={<Login />} />}

      </Routes>
    </div>
  );
}

export default App;
