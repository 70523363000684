import React from 'react';
import Header2 from '../Dashboard/Header2';
import Header from '../Dashboard/Header'
import FeatherIcon from 'feather-icons-react';

export default function Calendar(){



    return(
        <body class="main">
        <Header2/>
        <div class="flex">
            <nav class="side-nav">
                <Header/>
            </nav>
            <div class="content">
                <div class="top-bar">
                    <div class="-intro-x breadcrumb mr-auto hidden sm:flex"> <a href="">Application</a> <i data-feather="chevron-right" class="breadcrumb__icon"></i> <a href="" class="breadcrumb--active">Dashboard</a> </div>
                    <div class="intro-x relative mr-3 sm:mr-6">
                        <div class="search hidden sm:block">
                            <input type="text" class="search__input form-control border-transparent placeholder-theme-13" placeholder="Search..."/>
                            <i data-feather="search" class="search__icon dark:text-gray-300"></i> 
                        </div>
                        <a class="notification sm:hidden" href=""> <i data-feather="search" class="notification__icon dark:text-gray-300"></i> </a>
                        <div class="search-result">
                            <div class="search-result__content">
                                <div class="search-result__content__title">Pages</div>
                                <div class="mb-5">
                                    <a href="" class="flex items-center">
                                        <div class="w-8 h-8 bg-theme-18 text-theme-9 flex items-center justify-center rounded-full"> <i class="w-4 h-4" data-feather="inbox"></i> </div>
                                        <div class="ml-3">Mail Settings</div>
                                    </a>
                                    <a href="" class="flex items-center mt-2">
                                        <div class="w-8 h-8 bg-theme-17 text-theme-11 flex items-center justify-center rounded-full"> <i class="w-4 h-4" data-feather="users"></i> </div>
                                        <div class="ml-3">Users & Permissions</div>
                                    </a>
                                    <a href="" class="flex items-center mt-2">
                                        <div class="w-8 h-8 bg-theme-14 text-theme-10 flex items-center justify-center rounded-full"> <i class="w-4 h-4" data-feather="credit-card"></i> </div>
                                        <div class="ml-3">Transactions Report</div>
                                    </a>
                                </div>
                                <div class="search-result__content__title">Users</div>
                                <div class="mb-5">
                                    <a href="" class="flex items-center mt-2">
                                        <div class="w-8 h-8 image-fit">
                                            <img alt="Rubick Tailwind HTML Admin Template" class="rounded-full" src="dist/images/profile-4.jpg"/>
                                        </div>
                                        <div class="ml-3">Hugh Jackman</div>
                                        <div class="ml-auto w-48 truncate text-gray-600 text-xs text-right">hughjackman@left4code.com</div>
                                    </a>
                                    <a href="" class="flex items-center mt-2">
                                        <div class="w-8 h-8 image-fit">
                                            <img alt="Rubick Tailwind HTML Admin Template" class="rounded-full" src="dist/images/profile-1.jpg"/>
                                        </div>
                                        <div class="ml-3">Brad Pitt</div>
                                        <div class="ml-auto w-48 truncate text-gray-600 text-xs text-right">bradpitt@left4code.com</div>
                                    </a>
                                    <a href="" class="flex items-center mt-2">
                                        <div class="w-8 h-8 image-fit">
                                            <img alt="Rubick Tailwind HTML Admin Template" class="rounded-full" src="dist/images/profile-10.jpg"/>
                                        </div>
                                        <div class="ml-3">John Travolta</div>
                                        <div class="ml-auto w-48 truncate text-gray-600 text-xs text-right">johntravolta@left4code.com</div>
                                    </a>
                                    <a href="" class="flex items-center mt-2">
                                        <div class="w-8 h-8 image-fit">
                                            <img alt="Rubick Tailwind HTML Admin Template" class="rounded-full" src="dist/images/profile-3.jpg"/>
                                        </div>
                                        <div class="ml-3">Denzel Washington</div>
                                        <div class="ml-auto w-48 truncate text-gray-600 text-xs text-right">denzelwashington@left4code.com</div>
                                    </a>
                                </div>
                                <div class="search-result__content__title">Products</div>
                                <a href="" class="flex items-center mt-2">
                                    <div class="w-8 h-8 image-fit">
                                        <img alt="Rubick Tailwind HTML Admin Template" class="rounded-full" src="dist/images/preview-8.jpg"/>
                                    </div>
                                    <div class="ml-3">Dell XPS 13</div>
                                    <div class="ml-auto w-48 truncate text-gray-600 text-xs text-right">PC &amp; Laptop</div>
                                </a>
                                <a href="" class="flex items-center mt-2">
                                    <div class="w-8 h-8 image-fit">
                                        <img alt="Rubick Tailwind HTML Admin Template" class="rounded-full" src="dist/images/preview-4.jpg"/>
                                    </div>
                                    <div class="ml-3">Samsung Q90 QLED TV</div>
                                    <div class="ml-auto w-48 truncate text-gray-600 text-xs text-right">Electronic</div>
                                </a>
                                <a href="" class="flex items-center mt-2">
                                    <div class="w-8 h-8 image-fit">
                                        <img alt="Rubick Tailwind HTML Admin Template" class="rounded-full" src="dist/images/preview-14.jpg"/>
                                    </div>
                                    <div class="ml-3">Apple MacBook Pro 13</div>
                                    <div class="ml-auto w-48 truncate text-gray-600 text-xs text-right">PC &amp; Laptop</div>
                                </a>
                                <a href="" class="flex items-center mt-2">
                                    <div class="w-8 h-8 image-fit">
                                        <img alt="Rubick Tailwind HTML Admin Template" class="rounded-full" src="dist/images/preview-7.jpg"/>
                                    </div>
                                    <div class="ml-3">Nike Tanjun</div>
                                    <div class="ml-auto w-48 truncate text-gray-600 text-xs text-right">Sport &amp; Outdoor</div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="intro-x dropdown mr-auto sm:mr-6">
                        <div class="dropdown-toggle notification notification--bullet cursor-pointer" role="button" aria-expanded="false"> <i data-feather="bell" class="notification__icon dark:text-gray-300"></i> </div>
                        <div class="notification-content pt-2 dropdown-menu">
                            <div class="notification-content__box dropdown-menu__content box dark:bg-dark-6">
                                <div class="notification-content__title">Notifications</div>
                                <div class="cursor-pointer relative flex items-center ">
                                    <div class="w-12 h-12 flex-none image-fit mr-1">
                                        <img alt="Rubick Tailwind HTML Admin Template" class="rounded-full" src="dist/images/profile-4.jpg"/>
                                        <div class="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"></div>
                                    </div>
                                    <div class="ml-2 overflow-hidden">
                                        <div class="flex items-center">
                                            <a href="javascript:;" class="font-medium truncate mr-5">Hugh Jackman</a> 
                                            <div class="text-xs text-gray-500 ml-auto whitespace-nowrap">06:05 AM</div>
                                        </div>
                                        <div class="w-full truncate text-gray-600 mt-0.5">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 20</div>
                                    </div>
                                </div>
                                <div class="cursor-pointer relative flex items-center mt-5">
                                    <div class="w-12 h-12 flex-none image-fit mr-1">
                                        <img alt="Rubick Tailwind HTML Admin Template" class="rounded-full" src="dist/images/profile-1.jpg"/>
                                        <div class="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"></div>
                                    </div>
                                    <div class="ml-2 overflow-hidden">
                                        <div class="flex items-center">
                                            <a href="javascript:;" class="font-medium truncate mr-5">Brad Pitt</a> 
                                            <div class="text-xs text-gray-500 ml-auto whitespace-nowrap">06:05 AM</div>
                                        </div>
                                        <div class="w-full truncate text-gray-600 mt-0.5">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#039;s standard dummy text ever since the 1500</div>
                                    </div>
                                </div>
                                <div class="cursor-pointer relative flex items-center mt-5">
                                    <div class="w-12 h-12 flex-none image-fit mr-1">
                                        <img alt="Rubick Tailwind HTML Admin Template" class="rounded-full" src="dist/images/profile-10.jpg"/>
                                        <div class="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"></div>
                                    </div>
                                    <div class="ml-2 overflow-hidden">
                                        <div class="flex items-center">
                                            <a href="javascript:;" class="font-medium truncate mr-5">John Travolta</a> 
                                            <div class="text-xs text-gray-500 ml-auto whitespace-nowrap">06:05 AM</div>
                                        </div>
                                        <div class="w-full truncate text-gray-600 mt-0.5">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 20</div>
                                    </div>
                                </div>
                                <div class="cursor-pointer relative flex items-center mt-5">
                                    <div class="w-12 h-12 flex-none image-fit mr-1">
                                        <img alt="Rubick Tailwind HTML Admin Template" class="rounded-full" src="dist/images/profile-3.jpg"/>
                                        <div class="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"></div>
                                    </div>
                                    <div class="ml-2 overflow-hidden">
                                        <div class="flex items-center">
                                            <a href="javascript:;" class="font-medium truncate mr-5">Denzel Washington</a> 
                                            <div class="text-xs text-gray-500 ml-auto whitespace-nowrap">01:10 PM</div>
                                        </div>
                                        <div class="w-full truncate text-gray-600 mt-0.5">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomi</div>
                                    </div>
                                </div>
                                <div class="cursor-pointer relative flex items-center mt-5">
                                    <div class="w-12 h-12 flex-none image-fit mr-1">
                                        <img alt="Rubick Tailwind HTML Admin Template" class="rounded-full" src="dist/images/profile-13.jpg"/>
                                        <div class="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"></div>
                                    </div>
                                    <div class="ml-2 overflow-hidden">
                                        <div class="flex items-center">
                                            <a href="javascript:;" class="font-medium truncate mr-5">Brad Pitt</a> 
                                            <div class="text-xs text-gray-500 ml-auto whitespace-nowrap">05:09 AM</div>
                                        </div>
                                        <div class="w-full truncate text-gray-600 mt-0.5">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomi</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="intro-x dropdown w-8 h-8">
                        <div class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in" role="button" aria-expanded="false">
                            <img alt="Rubick Tailwind HTML Admin Template" src="dist/images/profile-12.jpg"/>
                        </div>
                        <div class="dropdown-menu w-56">
                            <div class="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white">
                                <div class="p-4 border-b border-theme-27 dark:border-dark-3">
                                    <div class="font-medium">Hugh Jackman</div>
                                    <div class="text-xs text-theme-28 mt-0.5 dark:text-gray-600">Frontend Engineer</div>
                                </div>
                                <div class="p-2">
                                    <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"> <i data-feather="user" class="w-4 h-4 mr-2"></i> Profile </a>
                                    <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"> <i data-feather="edit" class="w-4 h-4 mr-2"></i> Add Account </a>
                                    <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"> <i data-feather="lock" class="w-4 h-4 mr-2"></i> Reset Password </a>
                                    <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"> <i data-feather="help-circle" class="w-4 h-4 mr-2"></i> Help </a>
                                </div>
                                <div class="p-2 border-t border-theme-27 dark:border-dark-3">
                                    <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"> <i data-feather="toggle-right" class="w-4 h-4 mr-2"></i> Logout </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
                    <h2 class="text-lg font-medium mr-auto">
                        Calendar
                    </h2>
                    <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
                        <button class="btn btn-primary shadow-md mr-2">Print Schedule</button>
                        <div class="dropdown ml-auto sm:ml-0">
                            <button class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300" aria-expanded="false">
                                <span class="w-5 h-5 flex items-center justify-center"> <i class="w-4 h-4" data-feather="plus"></i> </span>
                            </button>
                            <div class="dropdown-menu w-40">
                                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                    <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="share-2" class="w-4 h-4 mr-2"></i> Share </a>
                                    <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="settings" class="w-4 h-4 mr-2"></i> Settings </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-5 mt-5">
                    <div class="col-span-12 xl:col-span-4 2xl:col-span-3">
                        <div class="box p-5 intro-y">
                            <button type="button" class="btn btn-primary w-full mt-2"> <i class="w-4 h-4 mr-2" data-feather="edit-3"></i> Add New Schedule </button>
                            <div class="border-t border-b border-gray-200 dark:border-dark-5 mt-6 mb-5 py-3" id="calendar-events">
                                <div class="relative">
                                    <div class="event p-3 -mx-3 cursor-pointer transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-dark-1 rounded-md flex items-center">
                                        <div class="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
                                        <div class="pr-10">
                                            <div class="event__title truncate">VueJS Amsterdam</div>
                                            <div class="text-gray-600 text-xs mt-0.5"> <span class="event__days">2</span> Days <span class="mx-1">•</span> 10:00 AM </div>
                                        </div>
                                    </div>
                                    <a class="flex items-center absolute top-0 bottom-0 my-auto right-0" href=""> <i data-feather="edit" class="w-4 h-4 text-gray-600"></i> </a>
                                </div>
                                <div class="relative">
                                    <div class="event p-3 -mx-3 cursor-pointer transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-dark-1 rounded-md flex items-center">
                                        <div class="w-2 h-2 bg-theme-12 rounded-full mr-3"></div>
                                        <div class="pr-10">
                                            <div class="event__title truncate">Vue Fes Japan 2019</div>
                                            <div class="text-gray-600 text-xs mt-0.5"> <span class="event__days">3</span> Days <span class="mx-1">•</span> 07:00 AM </div>
                                        </div>
                                    </div>
                                    <a class="flex items-center absolute top-0 bottom-0 my-auto right-0" href=""> <i data-feather="edit" class="w-4 h-4 text-gray-600"></i> </a>
                                </div>
                                <div class="relative">
                                    <div class="event p-3 -mx-3 cursor-pointer transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-dark-1 rounded-md flex items-center">
                                        <div class="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
                                        <div class="pr-10">
                                            <div class="event__title truncate">Laracon 2021</div>
                                            <div class="text-gray-600 text-xs mt-0.5"> <span class="event__days">4</span> Days <span class="mx-1">•</span> 11:00 AM </div>
                                        </div>
                                    </div>
                                    <a class="flex items-center absolute top-0 bottom-0 my-auto right-0" href=""> <i data-feather="edit" class="w-4 h-4 text-gray-600"></i> </a>
                                </div>
                                <div class="text-gray-600 p-3 text-center hidden" id="calendar-no-events">No events yet</div>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label" for="checkbox-events">Remove after drop</label>
                                <input class="show-code form-check-switch ml-auto" type="checkbox" id="checkbox-events"/>
                            </div>
                        </div>
                        <div class="box p-5 intro-y mt-5">
                            <div class="flex">
                                <i data-feather="chevron-left" class="w-5 h-5 text-gray-600"></i> 
                                <div class="font-medium text-base mx-auto">April</div>
                                <i data-feather="chevron-right" class="w-5 h-5 text-gray-600"></i> 
                            </div>
                            <div class="grid grid-cols-7 gap-4 mt-5 text-center">
                                <div class="font-medium">Su</div>
                                <div class="font-medium">Mo</div>
                                <div class="font-medium">Tu</div>
                                <div class="font-medium">We</div>
                                <div class="font-medium">Th</div>
                                <div class="font-medium">Fr</div>
                                <div class="font-medium">Sa</div>
                                <div class="py-0.5 rounded relative text-gray-600">29</div>
                                <div class="py-0.5 rounded relative text-gray-600">30</div>
                                <div class="py-0.5 rounded relative text-gray-600">31</div>
                                <div class="py-0.5 rounded relative">1</div>
                                <div class="py-0.5 rounded relative">2</div>
                                <div class="py-0.5 rounded relative">3</div>
                                <div class="py-0.5 rounded relative">4</div>
                                <div class="py-0.5 rounded relative">5</div>
                                <div class="py-0.5 bg-theme-18 dark:bg-theme-9 rounded relative">6</div>
                                <div class="py-0.5 rounded relative">7</div>
                                <div class="py-0.5 bg-theme-1 dark:bg-theme-1 text-white rounded relative">8</div>
                                <div class="py-0.5 rounded relative">9</div>
                                <div class="py-0.5 rounded relative">10</div>
                                <div class="py-0.5 rounded relative">11</div>
                                <div class="py-0.5 rounded relative">12</div>
                                <div class="py-0.5 rounded relative">13</div>
                                <div class="py-0.5 rounded relative">14</div>
                                <div class="py-0.5 rounded relative">15</div>
                                <div class="py-0.5 rounded relative">16</div>
                                <div class="py-0.5 rounded relative">17</div>
                                <div class="py-0.5 rounded relative">18</div>
                                <div class="py-0.5 rounded relative">19</div>
                                <div class="py-0.5 rounded relative">20</div>
                                <div class="py-0.5 rounded relative">21</div>
                                <div class="py-0.5 rounded relative">22</div>
                                <div class="py-0.5 bg-theme-17 dark:bg-theme-11 rounded relative">23</div>
                                <div class="py-0.5 rounded relative">24</div>
                                <div class="py-0.5 rounded relative">25</div>
                                <div class="py-0.5 rounded relative">26</div>
                                <div class="py-0.5 bg-theme-14 dark:bg-theme-12 rounded relative">27</div>
                                <div class="py-0.5 rounded relative">28</div>
                                <div class="py-0.5 rounded relative">29</div>
                                <div class="py-0.5 rounded relative">30</div>
                                <div class="py-0.5 rounded relative text-gray-600">1</div>
                                <div class="py-0.5 rounded relative text-gray-600">2</div>
                                <div class="py-0.5 rounded relative text-gray-600">3</div>
                                <div class="py-0.5 rounded relative text-gray-600">4</div>
                                <div class="py-0.5 rounded relative text-gray-600">5</div>
                                <div class="py-0.5 rounded relative text-gray-600">6</div>
                                <div class="py-0.5 rounded relative text-gray-600">7</div>
                                <div class="py-0.5 rounded relative text-gray-600">8</div>
                                <div class="py-0.5 rounded relative text-gray-600">9</div>
                            </div>
                            <div class="border-t border-gray-200 dark:border-dark-5 pt-5 mt-5">
                                <div class="flex items-center">
                                    <div class="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
                                    <span class="truncate">Independence Day</span> 
                                    <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                                    <span class="font-medium xl:ml-auto">23th</span> 
                                </div>
                                <div class="flex items-center mt-4">
                                    <div class="w-2 h-2 bg-theme-1 dark:bg-theme-10 rounded-full mr-3"></div>
                                    <span class="truncate">Memorial Day</span> 
                                    <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                                    <span class="font-medium xl:ml-auto">10th</span> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12 xl:col-span-8 2xl:col-span-9">
                        <div class="box p-5">
                            <div class="full-calendar" id="calendar"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         </body>
    )
}