import React, { useState, useEffect } from 'react';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Header from '../Dashboard/Header';
import Header2 from '../Dashboard/Header2';
import {useNavigate} from 'react-router-dom'
import axios from 'axios';

export default function Task() {

    const navigation = useNavigate()
    const [offer, setOffer] = useState([])
    axios({
        method: 'get',
        url: "https://pure-crag-36612.herokuapp.com/getusers",
        header: {
          "accept": "application/json",
          "Content-Type": "application/json"
        }
      }).then((d)=>{
        setOffer(d.data)
      })

    return (
        <body class="main">
            <Header2 />
            <div class="flex">
            <nav class="side-nav">
                <Header/>
            </nav>
                <div class="content">
                    <DashboardHeader title="Task"/>
                    <h2 class="intro-y text-lg font-medium mt-10">
                        Available Offers
                </h2>
                    <div class="grid grid-cols-12 gap-6 mt-5">
                        {/* <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
                            <button class="btn btn-primary shadow-md mr-2">Add New User</button>
                            <div class="dropdown">
                                <button class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300" aria-expanded="false">
                                    <span class="w-5 h-5 flex items-center justify-center"> <i class="w-4 h-4" data-feather="plus"></i> </span>
                                </button>
                                <div class="dropdown-menu w-40">
                                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                        <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" class="w-4 h-4 mr-2"></i> Add Group </a>
                                        <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="message-circle" class="w-4 h-4 mr-2"></i> Send Message </a>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden md:block mx-auto text-gray-600">Showing 1 to 10 of 150 entries</div>
                            <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                                <div class="w-56 relative text-gray-700 dark:text-gray-300">
                                    <input type="text" class="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Search..." />
                                    <i class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" data-feather="search"></i>
                                </div>
                            </div>
                        </div> */}
                        <div class="intro-y col-span-12 md:col-span-6">
                            {offer.map((data)=>(<div class="box mt-2">
                                <div class="flex flex-col lg:flex-row items-center p-5">
                                    <div class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
                                        <img alt="Rubick Tailwind HTML Admin Template" class="rounded-full" src="dist/images/profile-14.jpg" />
                                    </div>
                                    <div class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
                                        <a href="" class="font-medium">{data.FullName}</a>
                                        <div class="text-gray-600 text-xs mt-0.5">{data.Email}</div>
                                        <div class="text-gray-600 text-xs mt-0.5">{data.TelephoneNumber}</div>
                                        <div class="text-gray-600 text-xs mt-0.5">{data.City}</div>
                                    </div>
                                    <div class="flex mt-4 lg:mt-0">
                                        <button class="btn btn-outline-secondary py-1 px-2">Completed</button>
                                    </div>
                                </div>
                            </div>))}
                        </div>
                    </div>
                </div>
            </div>
        </body>
    )
}