import React,{useState} from 'react';
import Header from '../Dashboard/Header';
import Header2 from '../Dashboard/Header2';
import DashboardHeader from '../Dashboard/DashboardHeader';
import Action from './Action';
import FeatherIcon from 'feather-icons-react';

export default function Activity() {

    
    const [ThumbUp, setThumbUp] = useState(0)
    const [ThumbDown, setThumbDown] = useState(0)
    const [Love, setLove] = useState(0)
    const [Likes, setLikes] = useState(0)
    const [Dislikes, setDislikes] = useState(0)

    return (
        <body class="main">
            <Header2 />
            <div class="flex">
                <Header />
                <div class="content">
                    <DashboardHeader title="Activity" />
                    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
                        <h2 class="text-lg font-medium mr-auto">
                            Potential partners
                    </h2>
                    </div>
                    <div class="intro-y grid grid-cols-12 gap-6 mt-5">
                        <Action Dislikes={Dislikes} Likes={Likes} ThumbUp={ThumbUp} ThumbDown={ThumbDown} Love={Love} setDislikes={setDislikes} setLikes={setLikes} setThumbUp={setThumbUp} setThumbDown={setThumbDown} setLove={setLove}/>
                        
                        
                    </div>
                </div>
            </div>
        </body>
    )
}