import React from 'react';
import {useNavigate} from 'react-router-dom';

export default function Error(){

    const navigation = useNavigate()
    const auth = false 
 function home(){
     console.log("hello")
     if(auth){
        navigation('/')
     }else {
         navigation("/Login")
     }
 }

    return(
        <body class="main">
        <div class="container">
            <div class="error-page flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left">
                <div class="-intro-x lg:mr-20">
                    <img alt="Rubick Tailwind HTML Admin Template" class="h-48 lg:h-auto" src="dist/images/error-illustration.svg"/>
                </div>
                <div class="text-white mt-10 lg:mt-0">
                    <div class="intro-x text-8xl font-medium">404</div>
                    <div class="intro-x text-xl lg:text-3xl font-medium mt-5">Oops. This page has gone missing.</div>
                    <div class="intro-x text-lg mt-3">You may have mistyped the address or the page may have moved.</div>
                    <button class="intro-x btn py-3 px-4 text-white border-white dark:border-dark-5 dark:text-gray-300 mt-10" onClick={()=>home()}>Back to Home</button>
                </div>
            </div>
        </div>
        </body>
    )
}