import React from 'react';
import Header from '../Dashboard/Header';
import Header2 from '../Dashboard/Header2';
import DashboardHeader from '../Dashboard/DashboardHeader';

export default function ViewTask(){
    return(
        <body class="main">
        <Header2/>
        <div class="flex">
            <Header/>
            <div class="content">
                <DashboardHeader title="View Task"/>
                <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
                    <h2 class="text-lg font-medium mr-auto">
                       Task full details
                    </h2>
                    <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
                        <button class="btn btn-primary shadow-md mr-2">Accept</button>
                        <div class="dropdown ml-auto sm:ml-0">
                        <button class="btn  shadow-md mr-2" style={{backgroundColor:"red", color:"#ffffff"}}>Reject</button>
                      
                            <div class="dropdown-menu w-40">
                                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                                    <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="file" class="w-4 h-4 mr-2"></i> Export Word </a>
                                    <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="file" class="w-4 h-4 mr-2"></i> Export PDF </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="intro-y box overflow-hidden mt-5">
                    <div class="border-b border-gray-200 dark:border-dark-5 text-center sm:text-left">
                        <div class="px-5 py-10 sm:px-20 sm:py-20">
                            <div class="text-theme-1 dark:text-theme-10 font-semibold text-3xl"><img scr="" alt="images"/></div>
                        </div>
                        <div class="flex flex-col lg:flex-row px-5 sm:px-20 pt-10 pb-10 sm:pb-20">
                            <div>
                                <div class="text-lg font-medium text-theme-1 dark:text-theme-10 mt-2">Arnold Schwarzenegger</div>
                                <div class="mt-1">arnodlschwarzenegger@gmail.com</div>
                                <div class="mt-1">260 W. Storm Street New York, NY 10025.</div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="px-5 sm:px-16 py-10 sm:py-20">
                        <div class="overflow-x-auto">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Colour</th>
                                        <th class="border-b-2 dark:border-dark-5 text-right whitespace-nowrap">Number</th>
                                        <th class="border-b-2 dark:border-dark-5 text-right whitespace-nowrap">Year purchase</th>
                                        <th class="border-b-2 dark:border-dark-5 text-right whitespace-nowrap">Year of Vehicle</th>
                                        <th class="border-b-2 dark:border-dark-5 text-right whitespace-nowrap">Model</th>
                                        <th class="border-b-2 dark:border-dark-5 text-right whitespace-nowrap">Mileage </th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                        <td class="border-b dark:border-dark-5">
                                            <div class="font-medium whitespace-nowrap">Vuejs Admin Template</div>
                                                </td>
                                        <td class="text-right border-b dark:border-dark-5 w-32">HTML, CSS</td>
                                        <td class="text-right border-b dark:border-dark-5 w-32">2</td>
                                        <td class="text-right border-b dark:border-dark-5 w-32 font-medium">Backend</td>
                                        <td class="text-right border-b dark:border-dark-5 w-32 font-medium">Backend</td>
                                        <td class="text-right border-b dark:border-dark-5 w-32 font-medium">Backend</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="px-5 sm:px-20 pb-10 sm:pb-20 flex flex-col-reverse sm:flex-row">
                    </div>
                </div>
            </div>
        </div>
    </body>
    )
}