import React from 'react'
import {Link, useNavigate} from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {Password} from '../SendData/Password'
import { PostData } from '../SendData/Postdata'
import {useAlert} from 'react-alert';
import env from "react-dotenv";

export default function Forgotten() {

    const navigation = useNavigate()
    const alert = useAlert()
    function handleSubmiit(a,b){
        Password(env.DB_FORGOTTEN,{"email":a.email}, navigation, alert)
    }
   


    return (
        <body class="login">
            <div class="container sm:px-10">
                <div class="block xl:grid grid-cols-2 gap-4">
                    <div class="hidden xl:flex flex-col min-h-screen">
                        <a href="" class="-intro-x flex items-center pt-5">
                            <img alt="Rubick Tailwind HTML Admin Template" class="w-6" src="favicon.ico" style={{height:50, width:50}} />
                            <span class="text-white text-lg ml-3"> Cycle<span class="font-medium">breeze</span> </span>
                        </a>
                        <div class="my-auto">
                            <img alt="Rubick Tailwind HTML Admin Template" class="-intro-x w-1/2 -mt-16" src="dist/images/illustration.svg" />
                            <div class="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                                A few more clicks to
                            <br />
                                recover your account.
                        </div>
                            <div class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500">Manage Cyclebreeze Community accounts</div>
                        </div>
                    </div>
                    <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
                        <div class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
                            <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
                                Forgotten
                        </h2>
                        <Formik
                                initialValues={{ email: ""}}
                                validationSchema={Yup.object({
                                    email: Yup.string().email('invalid email address').required('Required')
                                })}

                                onSubmit={(values, { setSubmitting }) => {
                                    handleSubmiit(values, setSubmitting)
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                }) => (
                                        <form onSubmit={handleSubmit}>

                            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">A few more clicks to recover your account.</div>
                            <div class="intro-x mt-8">
                                <Field type="text" name="email" class="intro-x login__input form-control py-3 px-4 border-gray-300 block" placeholder="Email" />
                            </div>
                            <div style={{color:"red", marginTop:10}}><ErrorMessage name="email"/></div>
                            <div class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4">
                                <Link to ="/Login">Back</Link>
                            </div>
                            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                                <button class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top" type="submit">Submit</button>
                            </div>
                            </form>
                                    )}
                            </Formik>                       
                        </div>
                        
                    </div>
                </div>
            </div>
        </body>
    )
}