import React from 'react';
import Header from '../Dashboard/Header'
import Header2 from '../Dashboard/Header2'
import DashboardHeader from '../Dashboard/DashboardHeader';
import FeatherIcon from 'feather-icons-react';
import AccountSetting from '../Account/AccountSettings';
import SettingSideBar from '../Account/SettingSiderbar';

export default function Reset_password() {
    return (
        <body class="main">
            <Header2/>
                        <div class="flex">
                <Header/>
                <div class="content">
                    <DashboardHeader title="Reset_password"/>
                    <div class="intro-y flex items-center mt-8">
                        <h2 class="text-lg font-medium mr-auto">
                            Change Password
                </h2>
                    </div>
                    <div class="grid grid-cols-12 gap-6">
                        <SettingSideBar/>
                        <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
                            <div class="intro-y box lg:mt-5">
                                <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                                    <h2 class="font-medium text-base mr-auto">
                                        Change Password
                            </h2>
                                </div>
                                <div class="p-5">
                                    <div>
                                        <label for="change-password-form-1" class="form-label">Old Password</label>
                                        <input id="change-password-form-1" type="password" class="form-control" placeholder="Input text" />
                                    </div>
                                    <div class="mt-3">
                                        <label for="change-password-form-2" class="form-label">New Password</label>
                                        <input id="change-password-form-2" type="password" class="form-control" placeholder="Input text" />
                                    </div>
                                    <div class="mt-3">
                                        <label for="change-password-form-3" class="form-label">Confirm New Password</label>
                                        <input id="change-password-form-3" type="password" class="form-control" placeholder="Input text" />
                                    </div>
                                    <button type="button" class="btn btn-primary mt-4">Change Password</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    )
}