import React,{useEffect} from 'react';
import { useParams} from 'react-router-dom';
import {GetData} from '../SendData/GetData'
import env from "react-dotenv";
import Lottie from 'react-lottie-player'
import community from '../Asset/community.json'

export default function ConfirmEmail(){

    const {id} =useParams()
    GetData(env.DB_CONFIRM+id)
    
    return(
        <div style={{justifyContent:"center", alignItems:"center", display:"flex", height:"95vh"}}>
          <Lottie
        loop
        animationData={community}
        play
        style={{height:"100%"}}
    />
      </div>
    )
}