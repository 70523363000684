import React from 'react';
import Header from '../Dashboard/Header';
import Header2 from '../Dashboard/Header2';
import DashboardHeader from '../Dashboard/DashboardHeader';

export default function Subscribtion(){
    return(
        <body class="main">
        <Header2/>
        <div class="flex">
            <Header/>
            <div class="content">
                <DashboardHeader title= "subscribtion"/>
                <div class="intro-y flex items-center mt-8">
                    <h2 class="text-lg font-medium mr-auto">
                    Prices
                    </h2>
                </div>
                <div class="intro-y box flex flex-col lg:flex-row mt-5">
                    <div class="intro-y flex-1 px-5 py-16">
                        <i data-feather="credit-card" class="block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"></i> 
                        <div class="text-xl font-medium text-center mt-10">An Hour</div>
                        <div class="text-gray-700 dark:text-gray-600 text-center mt-5"> Internet <span class="mx-1">•</span> Space <span class="mx-1">•</span> Ac </div>
                        <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2">We serve you with maximum satisfaction.</div>
                        <div class="flex justify-center">
                            <div class="relative text-5xl font-semibold mt-8 mx-auto"> <span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 35 </div>
                        </div>
                        <button type="button" class="btn btn-rounded-primary py-3 px-4 block mx-auto mt-8">PURCHASE NOW</button>
                    </div>
                    <div class="intro-y border-b border-t lg:border-b-0 lg:border-t-0 flex-1 p-5 lg:border-l lg:border-r border-gray-200 dark:border-dark-5 py-16">
                        <i data-feather="briefcase" class="block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"></i> 
                        <div class="text-xl font-medium text-center mt-10">One Day</div>
                        <div class="text-gray-700 dark:text-gray-600 text-center mt-5"> Internet <span class="mx-1">•</span> Space <span class="mx-1">•</span> Ac </div>
                        <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2">We serve you with maximum satisfaction.</div>
                        <div class="flex justify-center">
                            <div class="relative text-5xl font-semibold mt-8 mx-auto"> <span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 60 </div>
                        </div>
                        <button type="button" class="btn btn-rounded-primary py-3 px-4 block mx-auto mt-8">PURCHASE NOW</button>
                    </div>
                    <div class="intro-y flex-1 px-5 py-16">
                        <i data-feather="shopping-bag" class="block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"></i> 
                        <div class="text-xl font-medium text-center mt-10">One Week</div>
                        <div class="text-gray-700 dark:text-gray-600 text-center mt-5"> Internet <span class="mx-1">•</span> Space <span class="mx-1">•</span> Ac </div>
                        <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2">We serve you with maximum satisfaction.</div>
                        <div class="flex justify-center">
                            <div class="relative text-5xl font-semibold mt-8 mx-auto"> <span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div>
                        </div>
                        <button type="button" class="btn btn-rounded-primary py-3 px-4 block mx-auto mt-8">PURCHASE NOW</button>
                    </div>
                </div>
                <div class="intro-y box flex flex-col lg:flex-row mt-5">
                    <div class="intro-y flex-1 px-5 py-16">
                        <i data-feather="activity" class="block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"></i> 
                        <div class="text-xl font-medium text-center mt-10">Two Weeks</div>
                        <div class="text-gray-700 dark:text-gray-600 text-center mt-5"> Internet <span class="mx-1">•</span> Space <span class="mx-1">•</span> Ac </div>
                        <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2">We serve you with maximum satisfaction.</div>
                        <div class="flex justify-center">
                            <div class="relative text-5xl font-semibold mt-8 mx-auto"> <span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 35 </div>
                        </div>
                        <button type="button" class="btn btn-rounded-primary py-3 px-4 block mx-auto mt-8">PURCHASE NOW</button>
                    </div>
                    <div class="intro-y border-b border-t lg:border-b-0 lg:border-t-0 flex-1 px-5 lg:border-l lg:border-r border-gray-200 dark:border-dark-5 py-16">
                        <i data-feather="box" class="block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"></i> 
                        <div class="text-xl font-medium text-center mt-10">One Month</div>
                        <div class="text-gray-700 dark:text-gray-600 text-center mt-5"> Internet <span class="mx-1">•</span> Space <span class="mx-1">•</span> Ac </div>
                        <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2">We serve you with maximum satisfaction.</div>
                        <div class="flex justify-center">
                            <div class="relative text-5xl font-semibold mt-8 mx-auto"> <span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 60 </div>
                        </div>
                        <button type="button" class="btn btn-rounded-primary py-3 px-4 block mx-auto mt-8">PURCHASE NOW</button>
                    </div>
                    <div class="intro-y flex-1 px-5 py-16">
                        <i data-feather="send" class="block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"></i> 
                        <div class="text-xl font-medium text-center mt-10">One Year</div>
                        <div class="text-gray-700 dark:text-gray-600 text-center mt-5"> Internet <span class="mx-1">•</span> Space <span class="mx-1">•</span> Ac </div>
                        <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2">We serve you with maximum satisfaction.</div>
                        <div class="flex justify-center">
                            <div class="relative text-5xl font-semibold mt-8 mx-auto"> <span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div>
                        </div>
                        <button type="button" class="btn btn-rounded-primary py-3 px-4 block mx-auto mt-8">PURCHASE NOW</button>
                    </div>
                </div>
            </div>
        </div>
        
    </body>   
    )
}